@import "./config";


.modal-title {
  color: $primary;
}

@import "~bootstrap/scss/bootstrap";

.btn-brand1 {
  background-color: $primary;
  color: $dark;
  border-width: 0.2rem;
  position: relative;
}

.btn-brand1:hover {
  background-color: $secondary;
  color: $dark;
}

.boxBadge {
  position: absolute;
  right: 0;
  top: -.25em;
  text-align: right;
  line-height: 1;
}

.btn-brand1 .badge {
  font-size: .5em;
  top: 0;
  margin: 0 1px;
  padding: .5em;
  font-weight: 600;
}

.btn-outline-brand1 {
  background-color: white;
  color: $primary;
  border-color: $primary;
  border-width: 0.2rem;
}

.btn-outline-brand1:focus {
  background-color: white;
  color: $primary;
  border-color: $primary;
  border-width: 0.2rem;
}

.btn-link {
  color: $primary;
  font-weight: bold;
}

a {
  color: $primary;
  font-weight: bold;
}

.btn-link:hover {
  color: $secondary;
}

a:hover {
  color: $secondary;
}

/**/
.tagItem {
  border: 1px solid $primary;
}

.tagItem .close {
  background: $primary !important;
  color: $dark !important;
}

.card-body {
  box-shadow: 0 0 0 1px rgba($dark, 0.12), 0 4px 16px rgba($dark, 0.25);
}

.circleBox {
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 15px;
  border: 2px solid rgba($primary, 0.4);
}

.circleBox.checked {
  background: rgba($primary, 0.4);
}

.nav-tabs .nav-link {
  font-size: .9em;
}

.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .show > .nav-link,
.dropdown-menu .nav-item .dropdown-toggle.nav-link {
  color: $darkRed !important;
}

.navbar-dark {
  .navbar-toggler {
    position: absolute;
    right: .5em;
    top: .25em
  }
}

.navbar-light .navbar-brand.site-logo,
.site-logo {
  font-size: 2em;
  font-weight: bolder;
  color: $white;
}

.sidebar {
  .site-logo {
    color: $darkRed;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 99;
    background: $white;

    a {
      color: $darkRed;
      text-decoration: none;

      &:hover {
        color: $darkRed;
        text-decoration: none;
      }
    }

    i {
      position: absolute;
      right: 1.5em;
      bottom: .5em;
      font-size: .4em;
      color: $red;
      z-index: 9;
    }
  }
}

.site-logo img {
  width: 10%;
  min-width: 2em;
  max-width: 4em;
}

@media (max-width: 860px) {
  .site-logo img {
    min-width: 1.2em;
  }
}

.btn-secondary {
  color: $darkRed !important;
}

.emulatorScreen .btn.passiveChoiceBtn.btn-outline-dark,
.btn-outline-dark {
  background-color: $white;
  border-color: $darkRed !important;
  color: $darkRed !important;
}

.emulatorScreen .btn.passiveChoiceBtn.btn-purple,
.btn-purple {
  color: $white;
  background-color: $purple;
  border-color: $purple;
}

.emulatorScreen .btn.passiveChoiceBtn.btn-goldBorder,
.emulatorScreen .btn.passiveChoiceBtn.btn-yellow,
.btn-goldBorder,
.btn-yellow {
  color: $white;
  background-color: $yellow;
  border-color: $yellow;
}

.emulatorScreen .btn.passiveChoiceBtn.btn-goldBorder {
}

.btn-group .dropdown-menu {
  overflow-y: auto;
  max-height: 80vh
}
