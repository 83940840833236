@import "./config";
@import "./fonts/fonts";

html {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

#root {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.modal-body,
.modal-title {
  word-break: break-all;
}

.modal-title {
  white-space: nowrap;
  overflow: hidden;
}

.modal-title span {
  display: block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.modal-title span.emoji {
  display: inline;
}

.wrapper {
  position: relative;
  top: 0;
  height: 100%;
}

.sidebar {
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 260px;
  background: $white;
  color: $dark;
  transition: width 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  overflow: hidden;
  padding: 0;


  .sidebarVersion {
    position: absolute;
    left: 0;
    right: 0;
    padding: .5em 1em;
    font-size: .7em;
    bottom: 0;
    border-top: 1px solid $gray-300 !important;
    background: $white;
    z-index: 99;
    text-align: right;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .sidebar-content {
    width: 260px;
    height: calc(100% - 7em);
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    padding: 0 1em;
    margin: 4em 0 3em 0;
  }

  .btn {
    position: relative;
  }
}

.main-panel {
  position: relative;
  margin-left: 260px;
  overflow: visible;
  width: calc(100% - 260px);
  height: 100%;
  -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.main-panel.preview-panel {
  width: calc(100% - 260px - 30em);
}

.menuCollapsed {
  .sidebar {
    width: 0;
    overflow: hidden;
    padding: 15px 0 15px 30px;

    * {
      opacity: 0;
    }
  }

  .main-panel {
    width: calc(100% - 30px);
    margin-left: 30px;

    &.main-panel-sm {
      width: calc(70% - 30px);
    }

    &.preview-panel {
      width: calc(100% - 30px - 30em);
    }
  }
}

@media (max-width: 991px) {
  .main-panel.preview-panel,
  .main-panel {
    margin-left: 30px;
    width: calc(100% - 30px);
  }
  .sidebar {
    z-index: 999;
  }
}

.main-panel > .content {
  padding: 75px 15px 30px 15px;
  height: 100%;
  overflow: auto;
  scroll-behavior: smooth;
}

@media (max-width: 1024px) {
  .modal-dialog {
    max-width: 90%;
    margin: 1.75rem auto;
  }
}

.appOfflineBox {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  background: rgba($dark, .4);
  display: block;
}

.appOfflineBoxContent {
  background: #FFFFFF;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 1.5em 2em;
  font-size: 2em;
  color: $red
}

.page-link {
  color: $secondary;
}

.page-item.active .page-link {
  color: $darkRed;
  background-color: $secondary;
  border-color: $secondary;
}

.modal-body, .modal-title {
  word-break: break-word;
}

.modal {
  z-index: 99999;
}

.stickyImgBox {
  top: 0;
  position: -webkit-sticky;
  position: sticky;
}

.privacy-policy-content {
  padding: 0;
}

.privacy-policy-content h3 {
  padding: 1em 0 .5em;
}

.privacy-policy-content h6 {
  padding: 1em 0 .5em;
  font-weight: bold;
}

.privacy-policy-content {
}

.ImgFront {
  height: 420px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0%, -50%);
}

.signUpPageContent {
  position: relative;
}

.signUpPageContent .row {
  height: 100%;
}

.signUpPageContent-box-1 {
  height: 580px;
}

.signUpPageContent-box-2 {
  height: 580px;
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  overflow: auto;
  transition: all 1s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.signUpPageContent-box-2.active {
  left: 0;
}

.signUpPageContent-box-1 div,
.signUpPageContent-box-2 div {
  position: relative;
}

.signUpPageContent-box-2 .ImgFront {
  left: auto;
  right: 0;
}

.form-control.is-invalid {
  border-color: #dc3545 !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
}

.btn.disabled, .btn:disabled {
  cursor: default !important;
}

.navbar-expand-lg .navbar-nav {
  align-items: center;
}


@media (max-width: 990px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute !important;
  }
  .userNavItem {
    min-width: 30%;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.row {
  margin-right: -.25rem;
  margin-left: -.25rem;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
  padding-right: .25rem;
  padding-left: .25rem;
}


