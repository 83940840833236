// TODO: this should be part of CT, not needed as part of AT
// only for emulator to work
@font-face {
  font-family: "Lato";
  src: url("./lato/Lato-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("./lato/Lato-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("./lato/Lato-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("./lato/Lato-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Lato";
  src: url("./lato/Lato-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Lato";
  src: url("./lato/Lato-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Eponymous";
  src: url("./eponymous/Eponymous-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Eponymous";
  src: url("./eponymous/Eponymous-Italic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}
